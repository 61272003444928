import { Standard, assert } from '@microsoft/vscodeedu-api';
import { produce } from 'immer';
import React, { Dispatch, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { ContextProps, StoreState, anonymousClient } from '.';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent } from '../utilities/diagnostics';

// Standards store.
export type StandardStore = Readonly<{
    state: StoreState;
    items: Map<string, Standard>;
}>;

// Standards store store action.
export type StandardAction =
    | { type: 'Load' }
    | { type: 'LoadResult'; items: Map<string, Standard> }
    | { type: 'LoadError' };

// Standards context.
export const StandardContext = createContext<[state: StandardStore, reducer: Dispatch<StandardAction>]>(undefined!);

// Standards context provider.
export const StandardContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const runner = useAsyncRunner();

    // Lists all available standards.
    const listStandards = useCallback(
        async () =>
            runner.run({
                task: async () => {
                    const groups = await anonymousClient.listStandardGroups();
                    const map = (
                        await Promise.all(
                            groups.map(async (o) => {
                                const items = await anonymousClient.listStandards(o.id);
                                return items.map<[string, Standard]>((o) => [o.id, o]);
                            })
                        )
                    ).flat();
                    dispatch({ type: 'LoadResult', items: new Map(map) });
                },
                onError: () => {
                    dispatch({ type: 'LoadError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error occurred while loading education standards information.',
                }),
            }),
        [intl, runner]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: StandardStore, action: StandardAction) =>
            produce(store, (draft) => {
                traceEvent('standard-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'Load': {
                        if (draft.state === 'NotLoaded') {
                            listStandards();
                            draft.state = 'Loading';
                        }
                        break;
                    }
                    case 'LoadResult':
                        draft.state = 'Loaded';
                        draft.items = action.items;
                        break;
                    case 'LoadError':
                        draft.state = 'NotLoaded';
                        break;
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [listStandards]
    );

    const [state, dispatch] = useReducer(reducer, { state: 'NotLoaded', items: new Map() });
    return <StandardContext.Provider value={[state, dispatch]}>{props.children}</StandardContext.Provider>;
});

// Use available standards.
export const useStandards = () => {
    const [store, dispatch] = useContext(StandardContext);
    useEffect(() => dispatch({ type: 'Load' }), [dispatch]);
    return [store, dispatch] as [typeof store, typeof dispatch];
};
