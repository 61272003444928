import { Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { ProfileTheme } from '@microsoft/vscodeedu-common';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '../../data';
import { useTheme } from '../../data/theme-context';
import { TrackedMenuItemRadio } from '../common';
import { useAppStyles } from '../../styles';

export default () => {
    const { themeMenu, highContrastThemes } = useConfig();
    const [{ profileTheme }, dispatch] = useTheme();
    const appStyles = useAppStyles();
    return (
        <>
            {themeMenu && (
                <Menu>
                    <MenuTrigger>
                        <MenuItem className={appStyles.menuListItem}>
                            <FormattedMessage description="Header menu item text" defaultMessage="Color theme" />
                        </MenuItem>
                    </MenuTrigger>
                    <MenuPopover className={appStyles.menuPopover}>
                        <MenuList
                            checkedValues={{ theme: [profileTheme] }}
                            onCheckedValueChange={(_, { checkedItems }) =>
                                dispatch({ type: 'Set', theme: checkedItems[0] as ProfileTheme })
                            }
                        >
                            <TrackedMenuItemRadio trackAction="menu-dark-theme" name="theme" value={ProfileTheme.Dark}>
                                <FormattedMessage description="Header menu item text" defaultMessage="Dark" />
                            </TrackedMenuItemRadio>
                            <TrackedMenuItemRadio
                                trackAction="menu-light-theme"
                                name="theme"
                                value={ProfileTheme.Light}
                            >
                                <FormattedMessage description="Header menu item text" defaultMessage="Light" />
                            </TrackedMenuItemRadio>
                            {highContrastThemes && (
                                <>
                                    <TrackedMenuItemRadio
                                        trackAction="menu-dark-theme"
                                        name="theme"
                                        value={ProfileTheme.HighContrastDark}
                                    >
                                        <FormattedMessage
                                            description="Header menu item text"
                                            defaultMessage="Dark high contrast"
                                        />
                                    </TrackedMenuItemRadio>
                                    <TrackedMenuItemRadio
                                        trackAction="menu-light-theme"
                                        name="theme"
                                        value={ProfileTheme.HighContrastLight}
                                    >
                                        <FormattedMessage
                                            description="Header menu item text"
                                            defaultMessage="Light high contrast"
                                        />
                                    </TrackedMenuItemRadio>
                                </>
                            )}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )}
        </>
    );
};
